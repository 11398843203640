@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=latin-ext');
@import "../metronic/sass/layouts/layout3/layout";
@import "../metronic/sass/layouts/layout3/themes/ferling";

@import "bootstrap-select/bootstrap-select";
@import "dropzone/basic";
@import "fancybox";
@import "auth";
@import "fullcalendar/fullcalendar";
@import "datetimepicker/bootstrap-datetimepicker";

@function get-color($color-name) {
	@if(map-has-key($component-colors, $color-name)) {
		$color-map: map-get($component-colors, $color-name);
		@return map-get($color-map, "base");
	} @else {
		@return "transparent";
	}
}

body {
	@include gradient-vertical(#fafafa, #e1e5ec, 50%, 100%);
	background-attachment: fixed;
}

body>main {
	padding-top: 1em;
}

.noresize {
	resize: none;
}

.flex {
	display: flex;
}

.crop {
	padding: 0;
	padding-bottom: 100%;
	height: 0;
	overflow: hidden;
	position: relative;
	
	img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		min-width: 100%;
		min-height: 100%;
		max-width: initial;
		max-height: 100%;
		
		&.allo {
			max-width: 100%;
			max-height: initial;
		}
		&.fekvo {
			max-width: initial;
			max-height: 100%;
		}
	}
}

.overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	
	div {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		text-align: center;
		font-size: 35px;
		color: white;
	}
}

.mt-checkbox {
	user-select: none;
	-moz-user-select: none;
}

.page-header .page-header-top.fixed {
	background: #fff !important;
	z-index: 7000;
}

.page-logo {
	margin-right: 1em;
	padding: 5px 0;
	border-right: 1px solid #f3f3f3;
	margin: 5px 1em 5px 0;
	padding-right: 1em;
	height: $header-height - 10px !important;
}

.page-logo-img {
	height: 45px;
}

.page-logo-title {
	font-size: 12px;
}

.navbar-nav>li {
	height: $header-height;

	>a {
		color: $text-color;
		font-size: 12px;
	}
}

.navbar-nav .icon-btn {
	background: transparent;
}

.icon-btn>div {
	position: absolute;
	left: 0;
	right: 0;
}

.navbar-avatar {
	height: $header-height - $navbar-padding-vertical * 2;
	margin-right: 7px;
}

$header-height-affix: 50px;
$navbar-padding-vertical-affix: 10px;

.page-header,
.page-header * {
	@include transition(all .2s ease-in-out);
}

.page-header .page-header-top.affix {
	&,
	.navbar-nav>li {
		height: $header-height-affix;
	}

	.page-logo-title,
	.icon-btn > div {
		height: 0;
		font-size: 0;
	}

	.navbar-nav > li > a {
		padding-top: $navbar-padding-vertical-affix;
		padding-bottom: $navbar-padding-vertical-affix;
	}

	.navbar-avatar {
		height: $header-height-affix - $navbar-padding-vertical-affix * 2;
	}

	.page-logo {
		height: $header-height-affix - 10px !important;
	}

	.page-logo-title {
		margin: 0;
	}

	.page-logo-img {
		height: 32px;
	}

	.icon-btn {
		height: calc(100% - 10px);
		min-width: 50px;
	}

	.header-search-form {
		height: $header-height-affix;
	}
}

.header-search-form {
	float: left;
	width: 30%;
	display: flex;
	height: $header-height;
	align-items: center;
}

.header-search-term {
	border: 0;
	background: transparentize($brand-main-color, .85);
}

.header-search-btn {
	background: transparentize($brand-main-color, .6);
	border-color: transparent;
	color: #fff;

	&:hover {
		color: #fff;
		background: transparentize($brand-main-color, .3);
		border-color: transparent;
	}

	&:active:hover {
		color: #fff;
		background: $brand-main-color;
		border-color: transparent;
	}
}

.uzenofal-balmenu {
	width: $container-md * .25;
	position: absolute;

	&.affix {
		position: fixed;
	}

	a {
		display: block;
		padding: $padding-large-vertical $padding-large-horizontal;
		@include transition(all .2s ease-out);
	}

	a, a:active, a:visited {
		color: $text-color;
	}

	a:hover {
		color: $link-color;
		background: #f1f1f1;
	}

	p:empty {
		margin: 0;
	}

	.flex {
		display: flex;
		justify-content: space-between;

		>span:first-child {
			flex-grow: 1;
		}

		>span+span {
			margin-left: 7px;
		}
	}

	.glyphicon-option-horizontal {
		&:before {
			@include transition(all .2s ease-in-out);
			display: block;
		}

		&[aria-expanded=true]:before {
			@include rotate(90deg);
		}
	}

}

#uj-bejegyzes {
	.portlet-title {
		border-bottom: 0;
		min-height: none;
		margin-bottom: 0;
	}

	.portlet-body {
		padding-top: 0;
	}

	.alsosor {
		justify-content: space-between;
		align-items: flex-end;
	}

	.segitseg a {
		color: $text-color;

		&:hover {
			color: $link-color;
		}
	}

	.submit-gombok {
		display: inline-block;
	}
}

.uzenet .csillagoz {
	&:hover {
		background-color: #fff !important;
		border-color: #bbc2ce !important;

		>* {
			color: get-color("yellow-crusta") !important;
		}
	}

	&:focus,
	&:active,
	&:focus:active {
		background-color: #fff !important;
	}

	&:focus:active {
		background-color: get-color("yellow-crusta") !important;

		>* {
			color: #fff !important;
		}
	}
}

.csillagozott.uzenet {
	.csillagoz {
		border-color: get-color("yellow-crusta") !important;

		>* {
			color: get-color("yellow-crusta") !important;
		}

		&:hover {
//			background-color: get-color("yellow-crusta") !important;
		}
	}
}

.giphies {
	img {
		background: url('/images/loading.gif') center no-repeat;
	}
}

.linkify {
	word-break: break-word;
	
	img {
		max-width: 100%;
	}
}

.mt-comments {
	.mt-comment {
		padding: 5px 10px;

		.mt-comment-body {
			.mt-comment-details {
				margin: 0;
				float: right;
			}
			.mt-comment-text {
				font-size: 13px;
			}
			.mt-comment-info {
				.mt-comment-author {
					margin-bottom: 5px;
				}
			}
		}
	}
}
