.auth-logo {
	width: 200px;
	margin: 3rem auto;
}

.auth-title {
	margin: 3rem auto;
	letter-spacing: 1px;
}

.auth-form {
	width: 330px;
	max-width: 90vw;
	margin: 0 auto;
	overflow-x: hidden;
	position: relative;
	background: #f3f3f3;
	padding: 1.5rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, .2);

	.checkbox {
		margin-bottom: 10px;
		font-weight: normal;
	}

	.form-control {
		position: relative;
		height: auto;
		padding: 10px;
		font-size: 16px;
	}

	.user-avatar {
		text-align: center;
		margin: 15px auto 30px;
	}

	.blank-user {
		display: inline-block;
		width: 1em;
		height: 1em;
		font-size: 10em;
		line-height: 1;
		border-radius: 50% !important;
		background: #eee;
		overflow: hidden;
		padding: .1em 0;
		color: #ccc;
		border: 2px solid #ccc;
		margin: 0 auto;
	}

	.btn {
		margin-top: 10px;
	}
}

.form-signin {
	.form-groups-wrap {
		overflow: hidden;
	}

	.form-groups {
		width: 200%;
		@include transition(all .15s ease-out);

		.form-group {
			float: left;
			width: 50%;
		}
	}

	.signin-back {
		position: absolute;
		top: 1.5rem;
		left: 1.5rem;
		display: block;
		opacity: 0;
		color: #bfbfbf;
		cursor: pointer;
		@include transition(all .2s ease-out);

		&.show {
			opacity: 1;
		}

		&.show:hover {
			color: darken(#bfbfbf, 15%);
		}
	}
}